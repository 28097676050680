<template>
  <div class="flex flex-col items-center justify-center border p-5 border-solid border-gray-400 rounded-lg">
    <div class="flex w-full">
      <div class="flex-grow font-bold self-center">{{ $t('barcode_type.custom_event_notification_setting') }}</div>
      <el-button type="primary" icon="el-icon-plus" size="medium" @click="addCustomEventNotification">
        {{ $t('barcode_type.add_new_rule') }}
      </el-button>
    </div>
    <div v-if="_customEventNotification.length !== 0" class="w-9/12">
      <div v-for="(customNotification, idx) in _customEventNotification" :key="idx" class="mb-4 px-12">
        <div class="flex items-center gap-4 py-3">
          <div class="flex items-center flex-grow">
            <div class="col-span-8 items-center whitespace-pre-line">
              <div v-html="customNotification.emailSubject"></div>
            </div>
          </div>
          <div class="mr-4 flex flex-row">
            <el-button type="primary" @click="openCustomValidator(idx)" size="medium">
              {{ $t('barcode_type.settings') }}
            </el-button>
            <el-button
              type="primary"
              class="bg-red-min text-white focus:text-white"
              @click="deleteCustomValidator(idx)"
              size="medium"
            >
              {{ $t('barcode_type.delete') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-if="isShowDialog">
    <CustomEventNotificationDialog
      :currentBCTypeCode="currentBCTypeCode"
      v-model:customEventNotification="currentSelectedCustomEvent"
      :isAddAction="currentIndex === -1"
      :barcodeTypes="barcodeTypes"
      @update:customEventNotification="saveCustomEventNotification"
      @dialog:close="closeDialog"
      :isDisabledActivateEvent="isDisabledActivateEvent"
    />
  </template>
</template>
<script lang="ts">
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import CustomEventNotificationDialog from '@/components/project/blocks/CustomEventNotificationDialog.vue'
import { VALIDATION_EVENT_TYPE_VALUE } from '@/utils/constants'
import { EEventType, ENotificationtiming } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  ICustomNotificationEvent,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'

export interface IRule {
  field: string
  fullField: string
  required: boolean
  type: string
  countryCode?: string
  validator: Function
}

@Options({
  components: { CustomEventNotificationDialog },
  emits: ['update:customNotificationEvent'],
  name: 'CustomNotificationEvent',
})
export default class CustomValidator extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @Prop({ type: Object }) readonly barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @Prop({ type: String }) readonly currentBCTypeCode!: string
  @PropSync('customNotificationEvent', { type: Array, default: [] })
  _customEventNotification!: ICustomNotificationEvent[]

  currentSelectedCustomEvent = {} as ICustomNotificationEvent
  currentIndex = -1
  isShowDialog = false

  @Watch('trackingPointDatasArr')
  onTrackPointChange() {
    this._customEventNotification.forEach((val, idx) => {
      if (val.eventType === VALIDATION_EVENT_TYPE_VALUE.tracked) {
        const endTPRouteAvailable = this.loadTP(val?.trackPointRoute?.from || '')
        const found = endTPRouteAvailable.find((val) => {
          return val.key === this._customEventNotification[idx].trackPointRoute?.to
        })
        if (!found) {
          this._customEventNotification.splice(idx, 1)
        }
      }
    })
  }

  get isDisabledActivateEvent() {
    const isActivateEventExist =
      this._customEventNotification?.some((val) => val.eventType === EEventType.ACTIVATING) || false
    return isActivateEventExist
  }

  loadTP(fromIdx: string) {
    if (isEmpty(fromIdx)) return [] as ITrackPointKeyVal[]

    const keys = Object.keys(
      (this.trackingPointDatasArr.find((tpData) => tpData.key === fromIdx) as ITrackPointKeyVal)?.value
        ?.trackPointForms ?? {}
    )

    return this.trackingPointDatasArr.filter((tpData) => keys.includes(tpData.key))
  }

  createCustomEventNotification(): ICustomNotificationEvent {
    return {
      eventType: EEventType.TRACKING,
      trackPointRoute: { from: '', to: '' },
      delayMinutes: 1,
      emailDestinations: [''],
      emailDestinationAliases: [''],
      emailSubject: '',
      emailBody: '',
      isDisplayOnMobile: false,
      mobileAlertDurationByMinutes: 0,
      triggerDateField: '',
      timingType: ENotificationtiming.AFTER,
    }
  }

  addCustomEventNotification() {
    const newNotificationEvent = cloneDeep(this.createCustomEventNotification())
    this.currentSelectedCustomEvent = newNotificationEvent
    this.currentIndex = -1
    this.isShowDialog = true
  }

  openCustomValidator(idx: number) {
    this.currentIndex = idx
    const customEventNotification = this._customEventNotification[idx]
    if (!customEventNotification.trackPointRoute) {
      customEventNotification.trackPointRoute = { from: '', to: '' }
    }
    if (
      !customEventNotification.emailDestinationAliases ||
      customEventNotification.emailDestinationAliases.length < 1
    ) {
      customEventNotification.emailDestinationAliases.push('')
    }
    if (customEventNotification.emailDestinations.length < 1) {
      customEventNotification.emailDestinations.push('')
    }

    this.currentSelectedCustomEvent = customEventNotification
    this.isShowDialog = true
  }

  closeDialog() {
    this.currentIndex = -1
    this.isShowDialog = false
  }

  deleteCustomValidator(idx: number) {
    this._customEventNotification.splice(idx, 1)
  }

  saveCustomEventNotification(data: ICustomNotificationEvent) {
    if (this.currentIndex === -1) {
      this._customEventNotification.push(data)
    } else {
      this._customEventNotification[this.currentIndex] = data
    }
    this.isShowDialog = false
  }

  getTrackingPointName(id?: string) {
    const trackingPointForm = this.trackingPointDatasArr.filter((item) => item.key === id)
    return trackingPointForm?.[0]?.value.name || ''
  }
}
</script>
