<template>
  <div class="relative mt-5 p-5 border-b border-gray-400 border-solid py-8">
    <div class="flex" style="place-content: space-between">
      <div class="font-bold">
        {{ $t('barcode_type.report_output') }}
      </div>
      <el-checkbox class="font-normal" @change="onToggleReportTemplate" v-model="isEnableReportTemplate">
        {{ $t('barcode_type.ouput_report_on_the_barcode_detai') }}
      </el-checkbox>
    </div>
    <div class="relative">
      <div v-for="(template, index) in syncedModelValue" :key="index" class="mt-5 custom-field-bg-color rounded-lg p-6">
        <el-form
          ref="ruleFormRef"
          :model="syncedModelValue[index]"
          :rules="rules"
          :disabled="isReadOnly || !isEnableReportTemplate"
        >
          <div>
            <div class="grid grid-cols-4 gap-x-4">
              <div class="border-r border-solid border-gray-600 flex justify-end pt-1 pr-3 font-normal text-right">
                {{ $t('barcode_type.template_file') }}<br />
                ({{ $t('barcode_type.xlsx_format') }})
              </div>
              <div class="col-span-3 flex flex-wrap gap-3 items-center">
                <div class="flex flex-grow">
                  <el-form-item class="w-full report-template-upload p-1" prop="templateFilePath">
                    <el-upload
                      :disabled="isReadOnly || !isEnableReportTemplate"
                      :ref="`uploadRef${index}`"
                      class="report-template-upload__container relative"
                      :auto-upload="false"
                      action=""
                      accept=".xlsx"
                      :show-file-list="false"
                      :on-change="changeUploadFile"
                    >
                      <template #trigger>
                        <el-button type="primary" @click="indexUpload = index">{{
                          $t('barcode_type.select')
                        }}</el-button>
                      </template>
                      <div class="report-template-upload__container__file">{{ template.fileName }}</div>
                      <el-button
                        type="primary"
                        class="btn-original ml-3 absolute right-1"
                        @click="submitUpload(index)"
                        :disabled="!isRawFileAvailable(index)"
                        >{{ $t('barcode_type.upload') }}</el-button
                      >
                    </el-upload>
                  </el-form-item>
                </div>
                <div class="flex pd-5-5">
                  <a
                    :href="syncedModelValue[index].fileUrl"
                    :ref="`btnDownloadTemplate${index}`"
                    target="__blank"
                    download
                  ></a>
                  <el-button
                    type="primary"
                    :disabled="syncedModelValue[index].templateFilePath === ''"
                    @click="downloadTemplate(index)"
                    >{{ $t('barcode_type.download') }}</el-button
                  >
                </div>
              </div>
              <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3 font-normal">
                {{ $t('barcode_type.ouput_format') }}
              </div>
              <div class="col-span-3 flex flex-wrap gap-3 items-center">
                <el-form-item prop="fileFormatType">
                  <el-radio-group v-model="syncedModelValue[index].fileFormatType">
                    <el-radio label="xlsx" value="xlsx">
                      {{ $t('barcode_type.xlsx') }}
                    </el-radio>
                    <el-radio label="pdf" value="pdf">
                      {{ $t('barcode_type.pdf') }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3 font-normal">
                {{ $t('button_label') }}
              </div>
              <div class="col-span-3 flex flex-wrap gap-3 items-center justify-between">
                <el-form-item prop="buttonLabel">
                  <el-input v-model="syncedModelValue[index].buttonLabel" name="label" class="w-80" />
                </el-form-item>
              </div>
              <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3 font-normal">
                {{ $t('file_name') }}
              </div>
              <div class="col-span-3 flex flex-wrap gap-3 items-center justify-between">
                <el-form-item prop="outputFileName">
                  <el-input v-model="syncedModelValue[index].outputFileName" name="outputFileName" class="w-80" />
                  <span class="m-2">.{{ syncedModelValue[index].fileFormatType }}</span>
                </el-form-item>

                <el-button
                  @click="onReportDelete(index)"
                  class="bg-red-min text-white focus:text-white"
                  v-if="index !== 0"
                >
                  {{ $t('delete') }}
                </el-button>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="absolute add-template-button">
        <el-button type="primary" @click="onAddTemplate()" :disabled="isReadOnly || !isEnableReportTemplate">{{
          $t('barcode_type.add_template')
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { getProjectAttachedFile, uploadProjectAttachedFile } from '@/utils/api'
import { BARCODE_REPORT_TEMPLATE, REPORT_TEMPLATE_FORMAT } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { randomString } from '@/utils/helpers'
import { openMessage } from '@/utils/utils'
import { UploadFile } from 'element-plus/lib/el-upload/src/upload.type'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcodeReportTemplate } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'ReportTemplateBlock',
})
export default class ReportTemplateBlock extends mixins(ValidateForm) {
  @Prop({ type: Boolean }) readonly isReadOnly?: boolean
  @PropSync('modelValue', {
    type: Object,
  })
  _syncedModelValue?: IBarcodeReportTemplate[]

  indexUpload = -1
  isEnableReportTemplate = false

  rules = {
    buttonLabel: [
      { required: this.isEnableReportTemplate || false, validator: this.requiredValidate, trigger: 'blur' },
    ],
  }

  get isEditMode() {
    return !!this.$store.state.project.projectDetail.mainInfo?.id
  }

  get projectCode() {
    return this.$store.state.project.projectDetail.mainInfo?.code ?? ''
  }

  get syncedModelValue(): IBarcodeReportTemplate[] {
    if (this._syncedModelValue && this._syncedModelValue.length > 0) {
      return this._syncedModelValue
    } else {
      this._syncedModelValue = [{ ...BARCODE_REPORT_TEMPLATE, code: randomString(5) }]
      return this._syncedModelValue
    }
  }

  set syncedModelValue(val: IBarcodeReportTemplate[]) {
    if (this._syncedModelValue) {
      this._syncedModelValue = val
    } else {
      this._syncedModelValue = [{ ...BARCODE_REPORT_TEMPLATE, code: randomString(5) }]
    }
  }

  @Watch('syncedModelValue')
  onChanged() {
    this.isEnableReportTemplate = !this.syncedModelValue.every((val) => {
      return (
        val?.templateFilePath === '' && val?.buttonLabel === '' && val?.fileFormatType === REPORT_TEMPLATE_FORMAT.xlsx
      )
    })
  }

  isRawFileAvailable(idx: number): boolean {
    return !isEmpty(this._syncedModelValue?.[idx]?.existFile)
  }

  async downloadTemplate(idx: number) {
    try {
      if (this.syncedModelValue?.[idx]?.templateFilePath) {
        this.syncedModelValue[idx].fileUrl = await getProjectAttachedFile(
          this.syncedModelValue?.[idx]?.templateFilePath || ''
        )
        setTimeout(() => {
          this.$refs[`btnDownloadTemplate${idx}`].click()
        }, 0)
      }
    } catch (err) {
      errorHandler(err)
    }
  }

  changeUploadFile(uploadFile: UploadFile) {
    if (this.syncedModelValue[this.indexUpload]) {
      this.syncedModelValue[this.indexUpload].fileName = uploadFile.name
      this.syncedModelValue[this.indexUpload].existFile = uploadFile
      this.syncedModelValue[this.indexUpload].isDisableUploadFile = false
    }
  }

  onFormReset() {
    if (!this.$refs.ruleFormRef) return
    this.$refs.ruleFormRef.resetFields()
  }

  get isRequiredReportTemplate() {
    return this.isEnableReportTemplate || false
  }

  checkValueObjectEmpty(obj: IBarcodeReportTemplate) {
    return Object.entries(obj).every((val) => isEmpty(val[1]))
  }

  onToggleReportTemplate(val: boolean) {
    if (val === true && this.isEditMode === false) {
      this.isEnableReportTemplate = false
      openMessage(this.$t('barcode_type.enable_report_template_warning'), 'warning')
      return false
    }
    if (!val) {
      this.onFormReset()
      this.syncedModelValue = [{ ...BARCODE_REPORT_TEMPLATE, code: randomString(5) }]
    }
  }

  async submitUpload(idx: number) {
    const formData = new FormData()
    formData.append('file', this.syncedModelValue[idx].existFile.raw)
    formData.append('projectCode', this.projectCode)

    try {
      const syncedModelValue = [...this.syncedModelValue]
      const uploadedFile = await uploadProjectAttachedFile(formData)
      syncedModelValue[idx].templateFilePath = uploadedFile
      this.$refs[`uploadRef${idx}`].clearFiles()
      syncedModelValue[idx].existFile = {} as UploadFile
      this.syncedModelValue = [...syncedModelValue]
      openMessage(this.$t('save_successful'), 'success')
    } catch (err) {
      errorHandler(err)
    } finally {
      this.indexUpload = -1
    }
  }

  onAddTemplate() {
    this.syncedModelValue.push({ ...BARCODE_REPORT_TEMPLATE, code: randomString(5) })
  }

  onReportDelete(index: number) {
    const syncedModelValue = [...this.syncedModelValue]
    syncedModelValue.splice(index, 1)
    this.syncedModelValue = [...syncedModelValue]
  }
}
</script>

<style lang="scss" scoped>
.report-template-upload {
  background-color: #f2f2f2;

  &__container {
    &__file {
      position: absolute;
      height: 100%;
      left: 100px;
      top: 0px;
    }
  }
}

.pd-5-5 {
  padding-bottom: 22px;
}

.add-template-button {
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 50%);
}
</style>
