<template>
  <div class="custom-dialog">
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="isShowDialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="closeDialog"
    >
      <template #title> {{ $t('barcode_type.notification_settings') }} </template>
      <div>
        <el-form
          class="frm-custom-validator"
          :label-position="'right'"
          label-width="170px"
          :rules="rules"
          :model="repeatTPNotificationData"
          ref="customEventNotificationForm"
        >
          <div class="flex items-center mb-5">
            <div>
              <el-form-item prop="eventType" :label="$t('tracking_point')">
                <el-select class="w-44" v-model="_selectedKey" :placeholder="$t('tracking_point')">
                  <el-option
                    :disabled="isTPKeyDisabled(val.key)"
                    v-for="(val, idx) in trackingPoints"
                    :key="idx"
                    :label="val.value?.name"
                    :value="val.key"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item prop="eventType" :label="$t('tp_visited_count_alert')">
                <InputOnlyNumber
                  :min="1"
                  v-model="repeatTPNotificationData.visitCountAlert"
                  name="maxLength"
                  :controls="true"
                  :precision="0"
                />
              </el-form-item>
            </div>
          </div>

          <div class="flex items-center mb-5 w-full">
            <el-form-item prop="emailDestinations" :label="$t('barcode_type.email_address')">
              <div v-for="(email, idx) in repeatTPNotificationData.emailDestinations" :key="idx" class="flex flex-row">
                <div class="w-3/4">
                  <el-form-item
                    :prop="`emailDestinations[${idx}]`"
                    :rules="[
                      {
                        required: isEmailAvail || !isEmailAliasAvail,
                        validator: isEmailAvail || !isEmailAliasAvail ? requiredEmailValidate : () => true,
                      },
                    ]"
                  >
                    <el-input size="medium" v-model="repeatTPNotificationData.emailDestinations[idx]"></el-input>
                  </el-form-item>
                </div>
                <div class="w-20 pt-1 pl-3 grid justify-items-end">
                  <div>
                    <el-button type="info" icon="el-icon-minus" size="small" circle @click="deleteEmail(idx)">
                    </el-button>
                    <el-button
                      v-if="idx === repeatTPNotificationData.emailDestinations.length - 1"
                      type="primary"
                      icon="el-icon-plus"
                      size="small"
                      circle
                      @click="addNewEmail"
                    >
                    </el-button>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>

          <div class="flex items-center mb-5 w-full ">
            <el-form-item
              class="w-full items-center pre-line"
              prop="emailDestinationAliases"
              :label="$t('barcode_type.email_custom_field')"
            >
              <div
                v-for="(alias, idx) in repeatTPNotificationData.emailDestinationAliases"
                :key="idx"
                :class="`flex flex-row ${idx !== 0 ? 'mt-4' : ''}`"
              >
                <div class="w-3/4">
                  <SelectAutocomplete
                    class=""
                    v-model="repeatTPNotificationData.emailDestinationAliases[idx]"
                    :options="emailCFs"
                  />
                </div>
                <div class="w-20 pt-1 pl-3 grid justify-items-end">
                  <div>
                    <el-button type="info" icon="el-icon-minus" size="small" circle @click="deleteEmailCF(idx)" />
                    <el-button type="primary" icon="el-icon-plus" size="small" circle @click="addNewEmailCF" />
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>

          <div class="flex items-center mb-5 w-full">
            <el-form-item prop="emailSubject" :label="$t('barcode_type.subject')">
              <el-input v-model="repeatTPNotificationData.emailSubject" size="medium" />
            </el-form-item>
          </div>
          <div class="flex items-center mb-5 w-full">
            <el-form-item prop="emailBody" :label="$t('barcode_type.display_message')">
              <el-input v-model="repeatTPNotificationData.emailBody" type="textarea" :rows="4" />
            </el-form-item>
          </div>

          <div class="flex items-center mb-5 w-full">
            <el-form-item prop="isDisplayOnMobile">
              <el-checkbox v-model="repeatTPNotificationData.isDisplayOnMobile" size="large">{{
                $t('barcode_type.is_display_on_mobile')
              }}</el-checkbox>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="closeDialog">
              {{ $t('barcode_type.cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="onSave" :disabled="isSaveDisabled">
              {{ $t('save') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  ICustomFieldFormData,
  IFieldOption,
  IRepeatedVisitingTrackPointNotificationDefinition,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['dialog:close', 'update:repeatedVisitingTrackPointAlertSettings', 'update:selectedKey'],
  components: { SelectAutocomplete, InputOnlyNumber },
  name: 'RepeatTrackingPointAlertDialog',
})
export default class RepeatTrackingPointAlertDialog extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @Prop({ type: Object }) readonly barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @Prop({ type: Object, default: {} })
  readonly repeatedVisitingTrackPointAlertSettings!: IRepeatedVisitingTrackPointNotificationDefinition

  @PropSync('selectedKey', { type: String, default: '' }) _selectedKey!: string
  @Prop({ type: Array, default: {} }) readonly selectedKeys!: string[]
  @Prop({ type: String }) readonly currentBCTypeCode!: string

  repeatTPNotificationData = {} as IRepeatedVisitingTrackPointNotificationDefinition
  isShowDialog = true
  isSaveDisabled = false
  rules = {
    visitCountAlert: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    isDisplayOnMobile: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailDestinations: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailDestinationAliases: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailSubject: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailBody: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
  } as {
    visitCountAlert: Array<unknown>
    emailDestinations: Array<unknown>
    emailDestinationAliases: Array<unknown>
    emailSubject: Array<unknown>
    emailBody: Array<unknown>
    isDisplayOnMobile: Array<unknown>
  }

  get trackingPoints() {
    return this.trackingPointDatasArr.filter((val) => !val.value?.isEnd)
  }

  isTPKeyDisabled(key: string) {
    return this.selectedKeys.includes(key)
  }

  get utcCountries() {
    const utcCountries: Array<{ offset: number; country: string }> = [
      { offset: 9, country: 'Japan Standard Time, Korea Standard Time' },
      { offset: -12, country: 'Baker Island, Howland Island' },
      { offset: -11, country: 'American Samoa' },
      { offset: -10, country: 'Hawaii' },
      { offset: -9, country: 'Alaska' },
      { offset: -8, country: 'Pacific Time (USA & Canada)' },
      { offset: -7, country: 'Mountain Time (USA & Canada)' },
      { offset: -6, country: 'Central Time (USA & Canada)' },
      { offset: -5, country: 'Eastern Time (USA & Canada)' },
      { offset: -4, country: 'Atlantic Time (Canada)' },
      { offset: -3, country: 'Argentina, Brazil (West), Greenland' },
      { offset: -2, country: 'South Georgia and the South Sandwich Islands' },
      { offset: -1, country: 'Azores, Cape Verde Islands' },
      { offset: 0, country: 'UTC, London' },
      { offset: 1, country: 'Central European Time (CET), West Africa Time' },
      { offset: 2, country: 'Eastern European Time (EET), Central Africa Time' },
      { offset: 3, country: 'Moscow, East Africa Time' },
      { offset: 4, country: 'Azerbaijan Standard Time' },
      { offset: 5, country: 'Pakistan Standard Time' },
      { offset: 6, country: 'Bangladesh Standard Time' },
      { offset: 7, country: 'Indochina Time' },
      { offset: 8, country: 'China Standard Time, Singapore Standard Time' },
      { offset: 10, country: 'Australian Eastern Standard Time' },
      { offset: 11, country: 'Magadan Time' },
      { offset: 12, country: 'Fiji, New Zealand (Chatham Islands)' },
      { offset: 13, country: 'Phoenix Island Time' },
      { offset: 14, country: 'Line Islands' },
    ]

    return utcCountries
  }

  customFieldFromOptions = [] as IFieldOption[]

  get trackingPointForm() {
    return this.trackingPointDatasArr.filter((item) => !isEmpty(item.value.trackPointForms))
  }

  get emailCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.EMAIL)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get isEmailAvail() {
    return !isEmpty(this.repeatTPNotificationData.emailDestinations.filter((v) => v !== ''))
  }

  get isEmailAliasAvail() {
    return !isEmpty(this.repeatTPNotificationData.emailDestinationAliases.filter((v) => v !== ''))
  }

  addNewEmail() {
    this.repeatTPNotificationData.emailDestinations.push('')
  }

  deleteEmail(idx: number) {
    if (this.repeatTPNotificationData.emailDestinations.length === 1 && idx === 0) return
    this.repeatTPNotificationData.emailDestinations.splice(idx, 1)
  }

  addNewEmailCF() {
    this.repeatTPNotificationData.emailDestinationAliases.push('')
  }

  deleteEmailCF(idx: number) {
    if (this.repeatTPNotificationData.emailDestinationAliases.length === 1 && idx === 0) return
    this.repeatTPNotificationData.emailDestinationAliases.splice(idx, 1)
  }

  created() {
    const repeatTPNotificationData = cloneDeep(this.repeatedVisitingTrackPointAlertSettings)
    this.repeatTPNotificationData = repeatTPNotificationData
  }

  closeDialog() {
    this.$emit('dialog:close')
  }

  onSave() {
    this.isSaveDisabled = true
    this.$refs.customEventNotificationForm.validate(async (valid: string) => {
      if (valid) {
        this.loading = true
        let isEmailValid = false

        if (this.hasEmail) this.$refs.emailForm.validate((valid: string) => (isEmailValid = !!valid))
        else {
          isEmailValid = true
          if (!isEmailValid) return
          const newRecord = cloneDeep(this.repeatTPNotificationData)
          newRecord.emailDestinations = [...new Set(newRecord.emailDestinations.filter((v) => v !== ''))]
          newRecord.emailDestinationAliases = [...new Set(newRecord.emailDestinationAliases.filter((v) => v !== ''))]
          this.repeatTPNotificationData = {
            ...this.repeatTPNotificationData,
            ...newRecord,
          }
          this.$emit('update:repeatedVisitingTrackPointAlertSettings', cloneDeep(this.repeatTPNotificationData))
          this.closeDialog()
        }
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 100%;

  &__label {
    width: 170px !important;
    line-height: 20px !important;
  }
}
.custom-dialog:deep() {
  .pre-line {
    & .el-form-item__label {
      line-height: 20px !important;
    }
  }

  &.el-overlay {
    .el-dialog {
      &.el-dialog--custom {
        width: 1000px !important;
        max-width: 1000px !important;
        margin-top: 0px !important;
      }
    }
  }
}
</style>
