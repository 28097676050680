
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { getProjectAttachedFile, uploadProjectAttachedFile } from '@/utils/api'
import { BARCODE_REPORT_TEMPLATE, REPORT_TEMPLATE_FORMAT } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { randomString } from '@/utils/helpers'
import { openMessage } from '@/utils/utils'
import { UploadFile } from 'element-plus/lib/el-upload/src/upload.type'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcodeReportTemplate } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'ReportTemplateBlock',
})
export default class ReportTemplateBlock extends mixins(ValidateForm) {
  @Prop({ type: Boolean }) readonly isReadOnly?: boolean
  @PropSync('modelValue', {
    type: Object,
  })
  _syncedModelValue?: IBarcodeReportTemplate[]

  indexUpload = -1
  isEnableReportTemplate = false

  rules = {
    buttonLabel: [
      { required: this.isEnableReportTemplate || false, validator: this.requiredValidate, trigger: 'blur' },
    ],
  }

  get isEditMode() {
    return !!this.$store.state.project.projectDetail.mainInfo?.id
  }

  get projectCode() {
    return this.$store.state.project.projectDetail.mainInfo?.code ?? ''
  }

  get syncedModelValue(): IBarcodeReportTemplate[] {
    if (this._syncedModelValue && this._syncedModelValue.length > 0) {
      return this._syncedModelValue
    } else {
      this._syncedModelValue = [{ ...BARCODE_REPORT_TEMPLATE, code: randomString(5) }]
      return this._syncedModelValue
    }
  }

  set syncedModelValue(val: IBarcodeReportTemplate[]) {
    if (this._syncedModelValue) {
      this._syncedModelValue = val
    } else {
      this._syncedModelValue = [{ ...BARCODE_REPORT_TEMPLATE, code: randomString(5) }]
    }
  }

  @Watch('syncedModelValue')
  onChanged() {
    this.isEnableReportTemplate = !this.syncedModelValue.every((val) => {
      return (
        val?.templateFilePath === '' && val?.buttonLabel === '' && val?.fileFormatType === REPORT_TEMPLATE_FORMAT.xlsx
      )
    })
  }

  isRawFileAvailable(idx: number): boolean {
    return !isEmpty(this._syncedModelValue?.[idx]?.existFile)
  }

  async downloadTemplate(idx: number) {
    try {
      if (this.syncedModelValue?.[idx]?.templateFilePath) {
        this.syncedModelValue[idx].fileUrl = await getProjectAttachedFile(
          this.syncedModelValue?.[idx]?.templateFilePath || ''
        )
        setTimeout(() => {
          this.$refs[`btnDownloadTemplate${idx}`].click()
        }, 0)
      }
    } catch (err) {
      errorHandler(err)
    }
  }

  changeUploadFile(uploadFile: UploadFile) {
    if (this.syncedModelValue[this.indexUpload]) {
      this.syncedModelValue[this.indexUpload].fileName = uploadFile.name
      this.syncedModelValue[this.indexUpload].existFile = uploadFile
      this.syncedModelValue[this.indexUpload].isDisableUploadFile = false
    }
  }

  onFormReset() {
    if (!this.$refs.ruleFormRef) return
    this.$refs.ruleFormRef.resetFields()
  }

  get isRequiredReportTemplate() {
    return this.isEnableReportTemplate || false
  }

  checkValueObjectEmpty(obj: IBarcodeReportTemplate) {
    return Object.entries(obj).every((val) => isEmpty(val[1]))
  }

  onToggleReportTemplate(val: boolean) {
    if (val === true && this.isEditMode === false) {
      this.isEnableReportTemplate = false
      openMessage(this.$t('barcode_type.enable_report_template_warning'), 'warning')
      return false
    }
    if (!val) {
      this.onFormReset()
      this.syncedModelValue = [{ ...BARCODE_REPORT_TEMPLATE, code: randomString(5) }]
    }
  }

  async submitUpload(idx: number) {
    const formData = new FormData()
    formData.append('file', this.syncedModelValue[idx].existFile.raw)
    formData.append('projectCode', this.projectCode)

    try {
      const syncedModelValue = [...this.syncedModelValue]
      const uploadedFile = await uploadProjectAttachedFile(formData)
      syncedModelValue[idx].templateFilePath = uploadedFile
      this.$refs[`uploadRef${idx}`].clearFiles()
      syncedModelValue[idx].existFile = {} as UploadFile
      this.syncedModelValue = [...syncedModelValue]
      openMessage(this.$t('save_successful'), 'success')
    } catch (err) {
      errorHandler(err)
    } finally {
      this.indexUpload = -1
    }
  }

  onAddTemplate() {
    this.syncedModelValue.push({ ...BARCODE_REPORT_TEMPLATE, code: randomString(5) })
  }

  onReportDelete(index: number) {
    const syncedModelValue = [...this.syncedModelValue]
    syncedModelValue.splice(index, 1)
    this.syncedModelValue = [...syncedModelValue]
  }
}
