<template>
  <div>
    <el-dialog
      v-model="modelValue_"
      custom-class="el-dialog--custom"
      width="650px"
      top="0"
      :title="$t('barcode_type.parser_setting')"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :show-close="true"
      @close="modelValue_ = false"
    >
      <div class="my-4">
        <!-- external id -->
        <!-- <div class="grid grid-cols-12 items-center gap-6 mb-5">
          <div class="text-right col-span-3 whitespace-nowrap">
            {{ $t('barcode_type.external_id') }}
          </div>
          <div class="col-span-9">
            <el-checkbox v-model="_parserSetting.isExternalId">{{ $t('available') }}</el-checkbox>
          </div>
        </div> -->
        <div
          v-for="(setting, idx) in settings"
          :key="idx"
          class="mt-6 rounded-md bg-white border-solid border border-gray-300"
        >
          <div class="flex justify-center relative pt-8 pb-2">
            <div>
              <div class="grid grid-cols-12 items-center gap-6 mb-5">
                <div class="text-right col-span-4 whitespace-nowrap">
                  {{ $t('barcode_type.parse_type') }}
                </div>
                <div class="col-span-8">
                  <el-radio-group v-model="setting.parserType">
                    <el-radio v-for="(item, idx) in EParseType" :key="idx" :value="item" :label="item">
                      {{ $t(`barcode_type.by_${item}`) }}
                    </el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="grid grid-cols-12 items-center gap-6 mb-5">
                <div class="text-right col-span-4 whitespace-nowrap">
                  {{ $t('barcode_type.target_custom_field') }}
                </div>
                <div class="col-span-8">
                  <el-select v-model="setting.targetFieldPath">
                    <el-option v-for="cf in parsableFields" :key="cf.key" :value="cf.key" :label="cf.label" />
                  </el-select>
                </div>
              </div>
              <!-- 3rd row -->
              <div>
                <!-- regex -->
                <div v-if="setting.parserType === EParseType.REGEX" class="grid grid-cols-12 items-center gap-x-6 mb-5">
                  <div class="flex self-start pt-3 justify-end col-span-4 whitespace-nowrap">
                    <span>{{ $t('barcode_type.rule') }}</span>
                  </div>
                  <div class="col-span-8">
                    <el-input v-model="setting.regexRule" type="textarea" rows="4" class="w-70" />
                  </div>
                  <div class="flex self-start mt-4 justify-end col-span-4 whitespace-nowrap">
                    <span>{{ $t('barcode_type.index') }}</span>
                  </div>
                  <div class="col-span-8">
                    <el-input-number
                      :min="1"
                      :controls="false"
                      class="my-2 w-14"
                      size="medium"
                      v-maska="'###'"
                      v-model="setting.index"
                    />
                  </div>
                </div>
                <!-- position-->
                <div
                  v-else-if="setting.parserType === EParseType.LENGTH"
                  class="grid grid-cols-12 items-center gap-6 mb-5"
                >
                  <div class="text-right col-span-4 whitespace-nowrap">
                    {{ $t('barcode_type.position') }}
                  </div>
                  <div class="col-span-8">
                    <el-input-number
                      :max="setting.endPosition"
                      :controls="false"
                      class="m-2 w-14"
                      size="medium"
                      v-maska="'###'"
                      v-model="setting.startPosition"
                    />
                    〜
                    <el-input-number
                      :min="setting.startPosition"
                      :controls="false"
                      class="ml-2 w-14"
                      size="medium"
                      v-maska="'###'"
                      v-model="setting.endPosition"
                    />
                  </div>
                </div>
                <!-- delimiter -->
                <div
                  v-else-if="setting.parserType === EParseType.DELIMITER"
                  class="grid grid-cols-12 items-center gap-6 mb-5"
                >
                  <div class="text-right col-span-4 whitespace-nowrap">
                    {{ $t('barcode_type.delimiter') }}
                  </div>
                  <div class="col-span-3">
                    <el-input v-model="setting.delimiter" class="w-20" />
                  </div>
                  <div class="col-span-2 text-right">
                    {{ $t('barcode_type.index') }}
                  </div>
                  <div class="col-span-3">
                    <el-input v-model="setting.index" class="w-14" />
                  </div>
                </div>
                <div v-else></div>
              </div>
            </div>
            <div class="absolute -bottom-4">
              <el-button type="info" icon="el-icon-minus" size="small" circle class="mx-2" @click="onRemoveItem(idx)" />
              <el-button
                v-if="idx === settings.length - 1"
                type="primary"
                icon="el-icon-plus"
                size="small"
                circle
                class="mx-2"
                @click="onAddItem()"
              />
            </div>
          </div>
        </div>
        <div class="mt-10 flex items-center justify-center">
          <el-button class="btn-light-blue rounded-md" @click="openParserTestDialog">
            {{ $t('barcode_type.test_parser') }}
          </el-button>
        </div>
      </div>
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="modelValue_ = false">
              {{ $t('barcode_type.cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="saveParserSetting">
              {{ $t('apply') }}
            </el-button>
          </div>
        </div>
      </template>
      <TestParserDialog v-model="isShowParserTestDialog" :settings="settings" :parsableFields="parsableFields" />
    </el-dialog>
  </div>
</template>
<script lang="ts">
import TestParserDialog from '@/components/project/dialogs/TestParserDialog.vue'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { maska } from 'maska'
import { TParsableField } from 'smartbarcode-web-core/src/bo/externalId'
import { ECustomFieldType, EParseType } from 'smartbarcode-web-core/src/utils/enums'
import { ICustomFieldFormData, TParserSettingItem } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, PropSync, Watch } from 'vue-property-decorator'

@Options({
  components: { TestParserDialog },
  directives: { maska },
  emits: ['update:modelValue', 'update:parserSetting'],
  name: 'ParserSettingDialog',
})
export default class ParserSettingDialog extends Vue {
  @InjectReactive() customFields!: ICustomFieldFormData[]

  @PropSync('modelValue', { type: Boolean }) modelValue_!: boolean
  @PropSync('parserSetting', { type: Object }) _parserSetting!: TParserSettingItem[]

  EParseType = EParseType
  DEFAULT_PARSER_SETTING_ITEM = Object.freeze(({
    parserType: EParseType.REGEX,
    targetFieldPath: '',
    regexRule: '',
  } as const) as TParserSettingItem)

  settings = [] as TParserSettingItem[]
  isShowParserTestDialog = false

  openParserTestDialog() {
    this.isShowParserTestDialog = true
  }

  saveParserSetting() {
    this._parserSetting = cloneDeep(this.settings.filter((i) => !isEmpty(i.targetFieldPath)))
    this.modelValue_ = false
  }

  get parsableFields() {
    return [
      { key: 'trackingNumber', label: this.$t('projects.trackingNumber'), type: ECustomFieldType.TEXT },
      { key: 'dimension.width', label: this.$t('product.width_cm'), type: ECustomFieldType.NUMBER },
      { key: 'dimension.weight', label: this.$t('product.weight_kg'), type: ECustomFieldType.NUMBER },
      { key: 'dimension.height', label: this.$t('product.height_cm'), type: ECustomFieldType.NUMBER },
      { key: 'dimension.depth', label: this.$t('product.depth_cm'), type: ECustomFieldType.NUMBER },
      ...this.customFields
        .filter((i) =>
          [
            ECustomFieldType.TEXT,
            ECustomFieldType.DATE,
            ECustomFieldType.EMAIL,
            ECustomFieldType.PHONE_NUMBER,
            ECustomFieldType.NUMBER,
            ECustomFieldType.SINGLE_SELECT,
            ECustomFieldType.MULTI_SELECT,
          ].includes(i.fieldType)
        )
        .map((cf) => ({
          key: `customFields.${cf.customFieldKey}`,
          label: cf.label,
          type: cf.fieldType,
          ...(!isEmpty(cf.selections) && { selections: cf.selections }),
          ...(!isEmpty(cf.countryCode) && { countryCode: cf.countryCode }),
        })),
    ] as TParsableField[]
  }

  onAddItem() {
    this.settings.push(cloneDeep(this.DEFAULT_PARSER_SETTING_ITEM))
  }

  onRemoveItem(idx: number) {
    this.settings.splice(idx, 1)
    if (this.settings.length === 0) this.onAddItem()
  }

  @Watch('parserSetting')
  onParserSettingChanged() {
    if (!isEqual(this.settings, this._parserSetting)) {
      this.settings = this._parserSetting ? cloneDeep(this._parserSetting) : []
    }
  }

  @Watch('modelValue')
  onModelValueChanged() {
    if (this.modelValue_ && isEmpty(this.settings)) this.onAddItem()
  }
}
</script>

<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

:deep {
  .el-overlay .el-dialog {
    max-width: 650px;
  }
}
</style>
