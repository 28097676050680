
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import TrackPointCheckboxesBlock from './TrackPointCheckboxesBlock.vue'
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import { DEFAULT_BARCODE_TYPES } from '@/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import cloneDeep from 'lodash/cloneDeep'
import Draggable from 'vuedraggable'

@Options({
  emits: [
    'update:modelValue',
    'update:currentBCType',
    'update:currentBCTypeCode',
    'update:originalBarcodeTypeCode',
    'update:recycleToLastestVersion',
  ],
  components: {
    BarcodeIcon,
    TrackPointCheckboxesBlock,
    Draggable,
  },
  name: 'BarcodeTypeButtonBlock',
})
export default class BarcodeTypeButtonBlock extends Vue {
  @PropSync('modelValue', { type: Object }) barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @PropSync('currentBCType', { type: Object, default: { ...DEFAULT_BARCODE_TYPES.default } })
  formModel!: IBarcodeDefinitionType

  @PropSync('currentBCTypeCode', { type: String, default: '' }) bcTypeCode!: string
  @PropSync('originalBarcodeTypeCode', { type: String, default: '' }) originalBCTypeCode!: string
  @PropSync('recycleToLastestVersion', { type: Boolean }) isRecycleToLastestVersion!: boolean

  @Prop({ type: Boolean, default: false }) readonly isReadOnly!: boolean

  selectedBarcodePosition = -1

  barcodeTypeEntries: [string, IBarcodeDefinitionType][] = []
  drag = false

  get draggableComponentData() {
    return {
      tag: 'div',
      type: 'transition-group',
      name: !this.drag ? 'flip-list' : null,
    }
  }

  get draggableBindingData() {
    return {
      animation: 100,
      disabled: this.isReadOnlyMode,
    }
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get isBarcodeTypeSelected() {
    return this.selectedBarcodePosition !== -1
  }

  @Watch('barcodeTypes', { immediate: true })
  onBarcodeTypesChanged() {
    const barcodeTypeEntries = Object.entries(this.barcodeTypes)
    barcodeTypeEntries.map((el, idx) => {
      if (!el[1].order) el[1].order = idx + 1
    })

    barcodeTypeEntries.sort((a, b) => (a[1].order && b[1].order && a[1].order > b[1].order ? 1 : -1))
    this.barcodeTypeEntries = barcodeTypeEntries
  }

  updateOrderBarcodeTypes() {
    this.drag = false
    this.barcodeTypeEntries.forEach((item, index) => (this.barcodeTypes[item[0]].order = index + 1))

    this.selectBCType(this.findIdxOfBCTypeCode(this.bcTypeCode))
    this.onBarcodeTypesChanged()
  }

  findIdxOfBCTypeCode(code: string): number {
    return this.barcodeTypeEntries.findIndex((o) => o[0] === code)
  }

  selectBCType(idx: number) {
    this.selectedBarcodePosition = idx
    this.bcTypeCode = this.barcodeTypeEntries[idx][0]
    this.originalBCTypeCode = this.barcodeTypeEntries[idx][0]
    this.formModel = this.barcodeTypeEntries[idx][1]
    this.isRecycleToLastestVersion = this.barcodeTypeEntries[idx][1]?.isRecycleToLastestVersion ?? false

    // history trace
    if (this.formModel) {
      this.formModel.activationDataVisibility = this.formModel?.activationDataVisibility ?? {
        showOrigin: false,
        showDestination: false,
        showTrackingNumber: false,
        showExternalId: false,
        showDimension: false,
        showProduct: false,
        showImageData: false,
        showFileData: false,
        showCustomFields: false,
        showLinkedBarcodes: false,
      }

      this.formModel.trackPointTracingVisibilities = this.formModel.trackPointTracingVisibilities ?? {}
    }
  }

  addNewBCType() {
    const newCode = Math.random()
      .toString(36)
      .substr(2, 10)
    const newBarcodeType = DEFAULT_BARCODE_TYPES.default
    let lastOrder = 1
    this.barcodeTypeEntries.forEach((item) => {
      const itemOrder = item[1].order
      if (itemOrder && lastOrder <= itemOrder) lastOrder = itemOrder + 1
    })
    newBarcodeType.order = lastOrder
    this.barcodeTypes[newCode] = cloneDeep({ ...newBarcodeType })
    this.onBarcodeTypesChanged()
    this.selectLatestBCType()
  }

  deleteBCType() {
    if (this.barcodeTypeEntries.length === 0) return
    const deletedBarcodeType = this.barcodeTypeEntries.filter((e, idx) => this.selectedBarcodePosition === idx)
    delete this.barcodeTypes[deletedBarcodeType[0][0]]
    this.onBarcodeTypesChanged()
    const fallbackIdx = this.selectedBarcodePosition - 1
    this.selectBCType(fallbackIdx === -1 ? 0 : fallbackIdx)
  }

  selectLatestBCType() {
    if (!isEmpty(this.barcodeTypes)) this.selectBCType(this.barcodeTypeEntries.length - 1)
  }

  isBCTypeCodeDuplicated(code: string): boolean {
    return !!this.barcodeTypeEntries.find((type, idx) => this.selectedBarcodePosition !== idx && type[0] === code)
  }

  cleanNewCode(newCode: string) {
    let count = 0
    let code = newCode
    while (this.isBCTypeCodeDuplicated(code)) code = `${newCode}_${++count}`
    return code
  }

  updateBCTypeCode(newCode: string, oldCode: string) {
    const bcType = this.barcodeTypeEntries[this.selectedBarcodePosition]
    if (newCode === oldCode) return
    newCode = this.cleanNewCode(newCode)
    bcType[0] = this.cleanNewCode(newCode)
    this.bcTypeCode = newCode
    this.barcodeTypes = Object.fromEntries(this.barcodeTypeEntries)
  }
}
