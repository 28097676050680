
import TestParserDialog from '@/components/project/dialogs/TestParserDialog.vue'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { maska } from 'maska'
import { TParsableField } from 'smartbarcode-web-core/src/bo/externalId'
import { ECustomFieldType, EParseType } from 'smartbarcode-web-core/src/utils/enums'
import { ICustomFieldFormData, TParserSettingItem } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, PropSync, Watch } from 'vue-property-decorator'

@Options({
  components: { TestParserDialog },
  directives: { maska },
  emits: ['update:modelValue', 'update:parserSetting'],
  name: 'ParserSettingDialog',
})
export default class ParserSettingDialog extends Vue {
  @InjectReactive() customFields!: ICustomFieldFormData[]

  @PropSync('modelValue', { type: Boolean }) modelValue_!: boolean
  @PropSync('parserSetting', { type: Object }) _parserSetting!: TParserSettingItem[]

  EParseType = EParseType
  DEFAULT_PARSER_SETTING_ITEM = Object.freeze(({
    parserType: EParseType.REGEX,
    targetFieldPath: '',
    regexRule: '',
  } as const) as TParserSettingItem)

  settings = [] as TParserSettingItem[]
  isShowParserTestDialog = false

  openParserTestDialog() {
    this.isShowParserTestDialog = true
  }

  saveParserSetting() {
    this._parserSetting = cloneDeep(this.settings.filter((i) => !isEmpty(i.targetFieldPath)))
    this.modelValue_ = false
  }

  get parsableFields() {
    return [
      { key: 'trackingNumber', label: this.$t('projects.trackingNumber'), type: ECustomFieldType.TEXT },
      { key: 'dimension.width', label: this.$t('product.width_cm'), type: ECustomFieldType.NUMBER },
      { key: 'dimension.weight', label: this.$t('product.weight_kg'), type: ECustomFieldType.NUMBER },
      { key: 'dimension.height', label: this.$t('product.height_cm'), type: ECustomFieldType.NUMBER },
      { key: 'dimension.depth', label: this.$t('product.depth_cm'), type: ECustomFieldType.NUMBER },
      ...this.customFields
        .filter((i) =>
          [
            ECustomFieldType.TEXT,
            ECustomFieldType.DATE,
            ECustomFieldType.EMAIL,
            ECustomFieldType.PHONE_NUMBER,
            ECustomFieldType.NUMBER,
            ECustomFieldType.SINGLE_SELECT,
            ECustomFieldType.MULTI_SELECT,
          ].includes(i.fieldType)
        )
        .map((cf) => ({
          key: `customFields.${cf.customFieldKey}`,
          label: cf.label,
          type: cf.fieldType,
          ...(!isEmpty(cf.selections) && { selections: cf.selections }),
          ...(!isEmpty(cf.countryCode) && { countryCode: cf.countryCode }),
        })),
    ] as TParsableField[]
  }

  onAddItem() {
    this.settings.push(cloneDeep(this.DEFAULT_PARSER_SETTING_ITEM))
  }

  onRemoveItem(idx: number) {
    this.settings.splice(idx, 1)
    if (this.settings.length === 0) this.onAddItem()
  }

  @Watch('parserSetting')
  onParserSettingChanged() {
    if (!isEqual(this.settings, this._parserSetting)) {
      this.settings = this._parserSetting ? cloneDeep(this._parserSetting) : []
    }
  }

  @Watch('modelValue')
  onModelValueChanged() {
    if (this.modelValue_ && isEmpty(this.settings)) this.onAddItem()
  }
}
