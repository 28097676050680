
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import RepeatTrackingPointAlertDialog from '@/components/project/blocks/RepeatTrackingPointAlertDialog.vue'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  IRepeatedVisitingTrackPointNotificationDefinition,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'

export interface IRule {
  field: string
  fullField: string
  required: boolean
  type: string
  countryCode?: string
  validator: Function
}

@Options({
  components: { RepeatTrackingPointAlertDialog },
  emits: ['update:repeatedVisitingTrackPointAlertSettings'],
  name: 'RepeatTrackingPointAlertBlock',
})
export default class RepeatTrackingPointAlertBlock extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @Prop({ type: Object }) readonly barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @Prop({ type: String }) readonly currentBCTypeCode!: string
  @PropSync('repeatedVisitingTrackPointAlertSettings', { type: Object, default: {} })
  _repeatVisitTPNotification!: Record<string, IRepeatedVisitingTrackPointNotificationDefinition>

  curentSelectNotification = {} as IRepeatedVisitingTrackPointNotificationDefinition
  curentSelectedKey = '0'
  isShowDialog = false

  get curentSelectedKeys() {
    return Object.keys(this._repeatVisitTPNotification)
  }

  get isDisableAddButton() {
    return isEmpty(this.generateKey())
  }

  @Watch('trackingPointDatasArr')
  onTrackPointChange() {
    Object.keys(this._repeatVisitTPNotification).forEach((key) => {
      const isKeyExists = this.trackingPointDatasArr.some((tpDataArr) => tpDataArr.key === key)
      if (!isKeyExists) {
        delete this._repeatVisitTPNotification[key]
      }
    })
  }

  loadTP(fromIdx: string) {
    if (isEmpty(fromIdx)) return [] as ITrackPointKeyVal[]

    const keys = Object.keys(
      (this.trackingPointDatasArr.find((tpData) => tpData.key === fromIdx) as ITrackPointKeyVal)?.value
        ?.trackPointForms ?? {}
    )

    return this.trackingPointDatasArr.filter((tpData) => keys.includes(tpData.key))
  }

  createRepeatVisitTPNotification(): Record<string, IRepeatedVisitingTrackPointNotificationDefinition> | null {
    const key = this.generateKey()
    if (key) {
      return {
        [key]: {
          emailDestinations: [''],
          emailDestinationAliases: [''],
          emailSubject: '',
          emailBody: '',
          isDisplayOnMobile: false,
          visitCountAlert: 1,
        },
      }
    }
    return null
  }

  generateKey() {
    const availableTP = this.trackingPointDatasArr.filter((val) => {
      return !this._repeatVisitTPNotification[val.key]
    })
    if (availableTP.length > 0) {
      return availableTP[0].key
    }
    return null
  }

  addCustomEventNotification() {
    const newNotificationEvent = cloneDeep(this.createRepeatVisitTPNotification())
    if (newNotificationEvent) {
      this.curentSelectedKey = Object.keys(newNotificationEvent)[0]
      this.curentSelectNotification = Object.values(newNotificationEvent)[0]
      this.isShowDialog = true
    }
  }

  openCustomValidator(key: string) {
    this.curentSelectedKey = key
    const repeatVisitTPNotification = this._repeatVisitTPNotification[key]
    if (
      !repeatVisitTPNotification.emailDestinationAliases ||
      repeatVisitTPNotification.emailDestinationAliases.length < 1
    ) {
      repeatVisitTPNotification.emailDestinationAliases.push('')
    }
    if (repeatVisitTPNotification.emailDestinations.length < 1) {
      repeatVisitTPNotification.emailDestinations.push('')
    }

    this.curentSelectNotification = repeatVisitTPNotification
    this.isShowDialog = true
  }

  closeDialog() {
    this.curentSelectedKey = '0'
    this.isShowDialog = false
  }

  deleteCustomValidator(key: string) {
    delete this._repeatVisitTPNotification[key]
  }

  saveCustomEventNotification(data: IRepeatedVisitingTrackPointNotificationDefinition) {
    this._repeatVisitTPNotification[this.curentSelectedKey] = data
    this.isShowDialog = false
  }

  getTrackingPointName(id?: string) {
    const trackingPointForm = this.trackingPointDatasArr.filter((item) => item.key === id)
    return trackingPointForm?.[0]?.value.name || ''
  }
}
