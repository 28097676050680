
import BaseInput from '@/components/common/BaseInput.vue'
import ProjectItem from '@/components/common/ProjectItem.vue'
import BarcodeType from '@/components/project/BarcodeType.vue'
import PreviewTab from '@/components/project/PreviewTab.vue'
import ProjectSettingsTab from '@/components/project/ProjectSettingsTab.vue'
import TrackingPointTab from '@/components/project/trackingPoint/TrackingPointTab.vue'
import LoziLogo from '@/components/svg/LoziLogo.vue'
import SmartBarcodeLogo from '@/components/svg/SmartBarcodeLogo.vue'
import {
  CLEAR_DETAIL_PROJECT,
  CLEAR_LOAD_PROJECTS_LIST,
  FETCH_PROJECT,
  FETCH_USER_GROUP_STAFF,
  INIT_PROJECT_DETAIL,
  LOAD_PROJECTS_LIST,
  SET_PAGE_NOT_FOUND,
  SET_PROJECT_READ_ONLY,
  UPDATE_BARCODE_TYPES,
  UPDATE_ORIGIN_PROJECT_DETAIL,
  UPDATE_TRACKING_POINTS,
} from '@/store/actions'
import { deleteDraftProject, publishDraftProject, publishProject, validateProjectUpdate } from '@/utils/api'
import { removeIf } from '@/utils/arrayUtils'
import {
  allowExitRoute,
  DEFAULT_PROJECT_INFORMATION_NO_TRACKPOINT,
  DRAFT_PROJECT_VERSION,
  LABEL_CHARACTERS_MAX,
  PROJECT_NEW_KEY_ID,
  VALIDATION_EVENT_TYPE_VALUE,
} from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { errorHandle, isEqualPermissionGroup, isImageFileField } from '@/utils/helpers'
import { openMessage } from '@/utils/utils'
import LocationTab from '@/views/LocationTab.vue'
import Permission from '@/views/Permission.vue'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { maska } from 'maska'
import { ECustomFieldType, EEventType } from 'smartbarcode-web-core/src/utils/enums/index'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeReportTemplate,
  IBaseProject,
  ICreateUserGroup,
  ICustomFieldFormData,
  ICustomNotificationEvent,
  ICustomValidator,
  IExternalWebAPIIntegration,
  INotificationRoute,
  IProject,
  IProjectDetailMainInfo,
  IProjectLocation,
  IProjectLocationPayload,
  IProjectUserGroup,
  IRoutePath,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types/index'
import { useForm } from 'vee-validate'
import { Options, setup, Vue } from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export declare type TActivationRouteKey =
  | 'trackPointRouteRestrictedPaths'
  | 'trackPointRouteNotifications'
  | 'trackPointRouteRemarks'
  | 'customValidators'
  | 'customNotificationEvent'

export declare type TActivationPointKey = 'linking' | 'pairing' | 'unpairing' | 'trackPointTracingVisibilities'
export declare type TActivationStringPointKey =
  | 'overridableStartTrackPoints'
  | 'bulkUpdatableTrackPoints'
  | 'barcodeLinkableTrackPoints'
  | 'barcodeRecyclableTrackPoints'
@Options({
  components: {
    SmartBarcodeLogo,
    LoziLogo,
    ProjectItem,
    Prop,
    TrackingPointTab,
    Permission,
    LocationTab,
    BarcodeType,
    BaseInput,
    PreviewTab,
    ProjectSettingsTab,
  },
  directives: { maska },
  name: 'ProjectForm',
})
export default class ProjectForm extends Vue {
  @Ref() trackPointTab!: TrackingPointTab
  @Ref() barcodeTypeTab!: BarcodeType
  @Ref() projectSettingsTab!: ProjectSettingsTab
  @Ref() permissionTab!: Permission
  @Ref() locationTab!: LocationTab
  @Ref() previewTab!: PreviewTab

  loading = false
  isFormInitiated = false
  isAllowToLeave = false
  pushTo = {}
  version = 1
  highestVersion = 1
  permissionGroup: ICreateUserGroup[] = []
  locationGroup: IProjectLocation[] = []
  prevTabIndex = 0
  mainInfo = {} as IProjectDetailMainInfo
  projectForm = { projectCodeError: false, projectNameError: false }

  isShowVersionUpdateConfirmation = false
  isKeepCurrentVersion = false
  isValidVersionUpdate = false

  previewKey = 0

  projectSettingTabs = {
    trackingPoint: 'trackingPoint',
    barcodeType: 'barcodeType',
    projectSettings: 'projectSettings',
    accessRight: 'accessRight',
    location: 'location',
    preview: 'preview',
  }

  isValidData = false

  // Default active tab name
  activeTabName = this.projectSettingTabs.trackingPoint

  closeConfirmVersionUpdateDialog() {
    this.isShowVersionUpdateConfirmation = false
  }

  get draftProjectVersionIndex() {
    return DRAFT_PROJECT_VERSION
  }

  get projectId() {
    return this.mainInfo?.id ?? ''
  }

  get projectCode() {
    return this.mainInfo?.code ?? ''
  }

  get originalProjects() {
    return this.$store.state.project.originalProjects
  }

  get otherDraftProjects(): IProject[] {
    return this.originalProjects.filter(
      (i: IProject) => i.isDraft && i.code === this.projectCode && i.id !== this.projectId
    )
  }

  get isDraftProject() {
    return this.mainInfo.isDraft
  }

  get projectHasDraft(): Record<string, unknown> {
    const existDraft = this.originalProjects.find(
      (i: IProject) => i.isDraft && i.code === this.mainInfo?.code && i.version === this.mainInfo.version
    )
    return { isHas: !!existDraft, id: existDraft?.id }
  }

  get isProjectHasOtherDraft() {
    return !isEmpty(this.otherDraftProjects)
  }

  get isSelectedBarcodeTypeTab() {
    return this.activeTabName === this.projectSettingTabs.barcodeType
  }

  get isSelectedTrackingPointTab() {
    return this.activeTabName === this.projectSettingTabs.trackingPoint
  }

  get code() {
    return this.$route.params.code
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get isDisabledProjectCode() {
    return !this.isCreatingNewProject
  }

  get isProjectParamsDraft(): boolean {
    return this.$route?.name === 'projectDraft' || false
  }

  get isCreatingNewProject() {
    return this.$route?.name === 'projectCreate'
  }

  get isDraftVersion(): boolean {
    return this.version === this.draftProjectVersionIndex
  }

  get isHighestVersion(): boolean {
    return this.version === this.highestVersion
  }

  async confirmSave() {
    this.isShowVersionUpdateConfirmation = false
    if (this.isProjectHasOtherDraft) await this.deleteDraftProjects()
    this.publishProject()
  }

  validateForm = setup(() => {
    const { handleSubmit } = useForm()
    return { onSubmit: handleSubmit(() => this.publishProject()) }
  })

  moveToProjectList() {
    this.isAllowToLeave = true
    this.$store.commit(CLEAR_LOAD_PROJECTS_LIST)
    this.$router.push({ name: 'projects' })
  }

  @Watch('loading')
  onProjectFromClicked() {
    if (this.loading === true || this.isFormInitiated) return

    this.isFormInitiated = true
    this.$store.commit(UPDATE_ORIGIN_PROJECT_DETAIL, this.fixProjectInfoForPayload())
  }

  async beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const orgPrj = this?.$store?.state?.project?.originalProjectDetail
    if (isEmpty(orgPrj)) {
      this.loading = false
      next()
      return
    }

    const isProjectNotInited = isEqual(cloneDeep(DEFAULT_PROJECT_INFORMATION_NO_TRACKPOINT), orgPrj)
    const originPermissionGroup = this?.$store?.state?.project?.originalPermissionGroupProject || []

    const isPrjSettingNotChanged =
      allowExitRoute.indexOf(to.path) !== -1 ||
      this.isAllowToLeave ||
      (isEqualPermissionGroup(
        this.formatUserGroups(this.permissionGroup),
        this.formatUserGroups(originPermissionGroup)
      ) &&
        isEqual(this.fixOriginalProjectInfoForPayload(), this.fixProjectInfoForPayload())) ||
      isProjectNotInited

    if (isPrjSettingNotChanged) {
      this.$store.commit(CLEAR_DETAIL_PROJECT)
      next()
    } else {
      await this.$confirm(this.$t('not_save_confirm'), this.$t('info'), {
        confirmButtonText: this.$t('confirm'),
        confirmButtonClass: 'danger',
        cancelButtonText: this.$t('cancel'),
      })
        .then(() => {
          this.$store.commit(CLEAR_DETAIL_PROJECT)
          next()
        })
        .catch(() => next(false))
    }
    this.loading = false
  }

  async created() {
    this.loading = true
    await this.$store.dispatch(FETCH_USER_GROUP_STAFF)

    await this.initProjectForm()
    if (!this.$store.state.project.isLoaded) {
      await this.$store.dispatch(LOAD_PROJECTS_LIST)

      // forward url to correct state of project in case user modify url and load project detail by code
      if (!this.isProjectParamsDraft && this.projectHasDraft.isHas) {
        this.$router.push({
          name: 'projectDraft',
          params: { version: this.highestVersion, code: this.projectHasDraft.id },
        })

        this.version = this.draftProjectVersionIndex
        this.loading = false
        return
      }
    }
    this.version = this.isProjectParamsDraft ? -1 : this.mainInfo.version || 1
    this.loading = false
  }

  @Watch('code')
  async initProjectForm() {
    if (this.code) return await this.fetchProjectData()

    this.$store.commit(INIT_PROJECT_DETAIL)
    this.loading = false
  }

  async fetchProjectData() {
    const projectId = this.isProjectParamsDraft ? this.code : this.projectId
    await this.$store
      .dispatch(FETCH_PROJECT, { projectId, projectCode: this.code, isDraft: this.isProjectParamsDraft })
      .then(() => (this.highestVersion = this.mainInfo.version ?? 1))
      .catch(() => {
        this.isAllowToLeave = true
        this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'project' })
      })
  }

  @Watch('$store.state.project.projectDetail.mainInfo')
  getMainInfo() {
    const isNewPrj = !this.$store.state.project?.projectDetail?.mainInfo?.id
    if (isNewPrj) return
    this.mainInfo = cloneDeep(this.$store.state.project?.projectDetail.mainInfo)
  }

  async onVersionChange(version: number) {
    this.loading = true
    const projectId = this.projectHasDraft.isHas ? this.projectHasDraft.id : '' // don't need id when load project with code and version

    await this.$store
      .dispatch(FETCH_PROJECT, { projectId, projectCode: this.projectCode, version, isDraft: this.isDraftVersion })
      .then(() => this.$store.commit(SET_PROJECT_READ_ONLY, !this.isHighestVersion && !this.isDraftVersion))
      .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'project' }))
      .finally(() => (this.loading = false))
  }

  updatePermission(payload: ICreateUserGroup[]) {
    this.permissionGroup = payload
  }

  updateLocation(payload: IProjectLocation[]) {
    this.locationGroup = payload
  }

  async actionSaveDraft() {
    if (!this.isProjectHasOtherDraft) return this.saveDraft()

    this.$confirm(this.$t('saveDraftProjectDeleteOtherDraft'), this.$t('confirm'), {
      confirmButtonText: this.$t('ok'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(async () => {
        try {
          await this.deleteDraftProjects()
          this.saveDraft()
        } catch (e) {
          errorHandler(e)
          return false
        }
      })
      .catch(() => true)
  }

  async saveDraft() {
    const doSaveDraft = async (): Promise<boolean> => {
      this.loading = true
      if (this.validate()) {
        const projectPayload = this.fixProjectInfoForPayload()
        try {
          await publishDraftProject(
            projectPayload,
            this.formatUserGroups(this.permissionGroup),
            this.formatLocations(this.locationGroup)
          )
          openMessage(this.$t('projects.draftSaved'), 'success')
          return true
        } catch (e) {
          errorHandle(e as Record<string, unknown>, (projectPayload as unknown) as Record<string, unknown>, true)
          return false
        } finally {
          this.loading = false
        }
      }

      this.loading = false
      return false
    }

    if (!(await doSaveDraft())) return
    this.moveToProjectList()
  }

  async deleteDraftProjects() {
    await Promise.all(this.otherDraftProjects.map((item: IProject) => deleteDraftProject(item.id as string)))
  }

  validate() {
    const validateTrackingPointTab = () => {
      const isValidateTrackPointTab = this.$refs.trackPointTab.validate()
      if (!isValidateTrackPointTab) this.activeTabName = this.projectSettingTabs.trackingPoint
      return isValidateTrackPointTab
    }
    const validateBarcodeTypeTab = () => {
      const isValidateBCTypeTab = this.$refs.barcodeTypeTab.validate()
      if (!isValidateBCTypeTab) this.activeTabName = this.projectSettingTabs.barcodeType
      return isValidateBCTypeTab
    }
    const validateProjectSettingsTab = () => {
      const isValidateProjectSettingsTab = this.$refs.projectSettingsTab.validate()
      if (!isValidateProjectSettingsTab) this.activeTabName = this.projectSettingTabs.projectSettings
      return isValidateProjectSettingsTab
    }

    return (
      this.validateCode() &&
      this.validateName() &&
      validateTrackingPointTab() &&
      validateBarcodeTypeTab() &&
      validateProjectSettingsTab()
    )
  }

  clearError() {
    this.projectForm = { projectCodeError: false, projectNameError: false }
  }

  validateCode() {
    this.clearError()
    this.mainInfo.code = this.mainInfo.code?.trim()

    this.projectForm.projectCodeError =
      (this.mainInfo?.code?.length || 0) < 3 || !this.mainInfo?.code?.match('^[a-zA-Z0-9-]+$')

    return !this.projectForm.projectCodeError
  }

  validateName() {
    this.clearError()
    try {
      if (isEmpty(this.mainInfo?.name)) throw this.$t('projects.project_name_cant_be_empty')
      if ((this.mainInfo?.name ?? '').length > LABEL_CHARACTERS_MAX) {
        throw this.$t('projects.project_name_character_max', { val1: LABEL_CHARACTERS_MAX })
      }
      this.projectForm.projectNameError = false
    } catch (message) {
      this.projectForm.projectNameError = true
      openMessage(message as string, 'error')
    }

    return !this.projectForm.projectNameError
  }

  checkDraftToPublishProject() {
    const validateProjectVersion = async () => {
      // all condition should validate in this function
      this.loading = true
      const projectPayload = this.fixProjectInfoForPayload()

      try {
        if (this.validate()) {
          if (!this.isDisabledProjectCode) await this.publishProject()
          else {
            try {
              await validateProjectUpdate(
                projectPayload,
                this.formatUserGroups(this.permissionGroup),
                this.formatLocations(this.locationGroup),
                this.isProjectParamsDraft
              )
              this.keepCurrentVersion()
              this.isShowVersionUpdateConfirmation = true
            } catch (err) {
              // error 4xxx => warning and force increase version
              if (typeof err === 'string' && err.startsWith('4')) {
                this.forceIncreaseVersion()
                this.isShowVersionUpdateConfirmation = true
              } else {
                errorHandler(err, {}, false)
              }
            }
          }
        }
      } catch (e) {
        errorHandle(
          e as Record<string, unknown>,
          (this.isProjectParamsDraft || isEmpty(projectPayload.id)
            ? projectPayload
            : { project: projectPayload }) as Record<string, unknown>,
          true
        )
      } finally {
        this.loading = false
      }
    }

    if (!this.isProjectHasOtherDraft) return validateProjectVersion()

    this.$confirm(this.$t('publishProjectDeleteOtherDraft'), this.$t('confirm'), {
      confirmButtonText: this.$t('ok'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(async () => {
        try {
          validateProjectVersion()
        } catch (e) {
          errorHandler(e)
          return false
        }
      })
      .catch(() => true)
  }

  forceIncreaseVersion() {
    this.isKeepCurrentVersion = false
    this.isValidVersionUpdate = false
  }

  keepCurrentVersion() {
    this.isKeepCurrentVersion = true
    this.isValidVersionUpdate = true
  }

  async publishProject() {
    this.loading = true
    const projectPayload = this.fixProjectInfoForPayload()
    try {
      if (this.validate()) {
        await publishProject(
          projectPayload,
          this.formatUserGroups(this.permissionGroup),
          this.formatLocations(this.locationGroup),
          this.isDraftVersion,
          !this.isKeepCurrentVersion
        )
        openMessage(this.$t('projects.publishSuccess'), 'success')
        this.moveToProjectList()
      }
    } catch (e) {
      errorHandle(
        e as Record<string, unknown>,
        (this.isProjectParamsDraft || isEmpty(projectPayload.id)
          ? projectPayload
          : { project: projectPayload }) as Record<string, unknown>,
        true
      )
    }
  }

  keyGroup = {
    settingTab: [
      'showBarcodeSearchPage',
      'showTrackingCountPage',
      'allowUnmatchedTracking',
      'allowForcePairing',
      'isAutoRecycleEndTrackingPoint',
      'isRecycleChildren',
    ],
    tpString: ['overridableStartTrackPoints', 'bulkUpdatableTrackPoints', 'barcodeLinkableTrackPoints'],
    tpObject: ['linking', 'pairing', 'unpairing'],
  }

  fixProjectInfoForPayload() {
    const prj = { ...this.mainInfo } as IBaseProject

    const settingsTab = this.$refs.projectSettingsTab
    const bcTypeTab = this.$refs.barcodeTypeTab
    const tpTab = this.$refs.trackPointTab
    if (settingsTab) {
      const settingTab = this.keyGroup.settingTab as Array<keyof IBaseProject>
      settingTab.forEach((k) => (prj[k] = settingsTab[k] as never))

      if (settingsTab.hasGoogleAnalytics && !isEmpty(settingsTab.googleAnalyticsMeasurementId.trim())) {
        prj.googleAnalyticsMeasurementId = settingsTab.googleAnalyticsMeasurementId
      } else delete prj.googleAnalyticsMeasurementId

      // force update store tps first before touch barcode type tab (In case user not click on barcodeTypeTab for update tps and just click publish btn)
      this.$store.commit(UPDATE_TRACKING_POINTS, tpTab.trackingPointDatas)
      if (bcTypeTab) prj.barcodeTypes = bcTypeTab.barcodeTypesPostPackage()
    }

    if (tpTab) prj.trackPoints = tpTab.trackingPointDatas

    return this.formatTrackPointObject(prj)
  }

  fixOriginalProjectInfoForPayload() {
    return this.formatTrackPointObject({ ...this.$store.state.project?.originalProjectDetail })
  }

  formatTrackPointObject(newObj: IProject) {
    const cloneNewObj = cloneDeep(newObj)
    const arrTrackingPoints = objectToArray(cloneNewObj.trackPoints)
    const maxTrackPointKey = cloneNewObj.trackPoints ? Object.keys(cloneNewObj.trackPoints).length - 1 : 0
    const isInvalidIdx = (idx: string | undefined) => !isEmpty(idx) && Number(idx) > maxTrackPointKey
    const isEndTPIdx = (tpKey: string | undefined) =>
      !isEmpty(tpKey) && !!arrTrackingPoints.find((tp) => tp.key === tpKey && tp.value.isEnd)
    const hasInvalidIdxs = (idxs: (string | undefined)[]) => idxs.some((idx) => isInvalidIdx(idx))
    const removeInvalidRoutes = function<T>(arr?: T[], forEach?: (item: T) => void) {
      ;(arr ?? []).forEach((i) => {
        forEach && forEach(i)

        // remove route if invalid
        Object.prototype.hasOwnProperty.call(i, 'from') &&
          Object.prototype.hasOwnProperty.call(i, 'to') &&
          removeIf<IRoutePath>(arr, (item) => hasInvalidIdxs([item.to, item.from]))
      })
    }

    for (const barcodes in cloneNewObj?.barcodeTypes) {
      const bcType = cloneNewObj?.barcodeTypes[barcodes]

      // remove auto save field ocr in activation custom fields
      const customFields = bcType.activationFields.customFields ?? {}
      const isImageFieldAvail = Object.values(customFields).find((f) => isImageFileField(f))
      Object.values(customFields).forEach((field) => {
        const isSupportSaveOCRImage =
          !isImageFieldAvail &&
          [
            ECustomFieldType.TEXT,
            ECustomFieldType.NUMBER,
            ECustomFieldType.PHONE_NUMBER,
            ECustomFieldType.EMAIL,
          ].includes(field.fieldType)
        if (isSupportSaveOCRImage) delete field.ocrImageAutoSaveField
      })

      this.keyGroup.tpString.forEach((key) => {
        removeIf(bcType[key as TActivationStringPointKey], (item) => isInvalidIdx(item as string))
        removeIf(bcType[key as TActivationStringPointKey], (item) => isEndTPIdx(item as string))
      })

      this.keyGroup.tpObject.forEach((key) => {
        const valueOfKey = bcType[key as TActivationPointKey] ?? {}
        Object.keys(valueOfKey)
          .filter((idx) => {
            return isInvalidIdx(idx) || isEndTPIdx(idx)
          })
          .forEach((idx) => delete valueOfKey[idx])
      })

      if (this.checkValueObjectEmpty(bcType?.externalWebAPIIntegration)) delete bcType?.externalWebAPIIntegration

      removeInvalidRoutes<ICustomValidator>(bcType.customValidators, (i) => {
        if (i.eventType !== VALIDATION_EVENT_TYPE_VALUE.tracked) delete i.trackPointRoute
      })
      removeInvalidRoutes<ICustomNotificationEvent>(bcType.customNotificationEvent, (i) => {
        if (i.eventType !== EEventType.TRACKING) delete i.trackPointRoute
      })
      removeInvalidRoutes(bcType.trackPointRouteNotifications, (item) => delete (item as INotificationRoute).isNew)
      removeInvalidRoutes(bcType.trackPointRouteRestrictedPaths)
      removeInvalidRoutes(bcType.trackPointRouteRemarks)

      removeIf<IBarcodeReportTemplate>(bcType.barcodeReportTemplates, (i) => isEmpty(i?.templateFilePath))
    }

    for (const iTP in cloneNewObj.trackPoints) {
      if (cloneNewObj.trackPoints[iTP].index) delete cloneNewObj.trackPoints[iTP].index

      const trackPoint: ITrackpoint = cloneNewObj.trackPoints[iTP]
      if (!trackPoint.trackPointForms) trackPoint.trackPointForms = {}
      const forms = trackPoint.trackPointForms ?? {}

      for (const iTPF in forms) {
        forms[iTPF].customFields = (forms[iTPF].customFields ?? {}) as Record<string, ICustomFieldFormData>
        const fields = forms[iTPF].customFields
        const isImageFieldAvail = Object.values(fields).find((f) => isImageFileField(f))
        Object.values(fields).forEach((field) => {
          if (!isImageFieldAvail && field.ocrImageAutoSaveField) field.ocrImageAutoSaveField = ''
          if (field.customFieldKey) delete field.customFieldKey
        })
      }
    }

    return cloneNewObj
  }

  checkValueObjectEmpty(obj?: IExternalWebAPIIntegration) {
    return isEmpty(obj) || (obj && Object.entries(obj).every((val) => isEmpty(val[1])))
  }

  changeTab(tab: Record<string, unknown>) {
    ;[
      () => this.$store.commit(UPDATE_TRACKING_POINTS, this.$refs.trackPointTab.trackingPointDatas),
      () => this.$store.commit(UPDATE_BARCODE_TYPES, cloneDeep(this.$refs.barcodeTypeTab.barcodeTypes)),
      () => {
        /** project settings tab */
      },
      () => (this.permissionGroup = this.$refs.permissionTab.permissionGroup),
      () => (this.locationGroup = this.$refs.locationTab.locations),
      () => {
        /** preview tab */
      },
    ][this.prevTabIndex]()
    this.prevTabIndex = Number(tab.index)
  }

  updateIndexTrackPointNode(deleteNode: string) {
    this.$refs.permissionTab.reindexPermissionGroup(deleteNode)
    this.$refs.locationTab.reindexLocationGroup(deleteNode)
    // this.$refs.barcodeTypeTab.reindexTrackPointData(deleteNode)
  }

  // updateTrackingPath(data: ITrackingPointDeletePath) {
  //   this.$refs.barcodeTypeTab.updateTrackingPath(data)
  // }

  onAddNewNode() {
    this.$refs.permissionTab.onAddNewNode()
    this.$refs.locationTab.onAddNewNode()
  }

  formatUserGroups(permissionGroup: ICreateUserGroup[]): IProjectUserGroup[] {
    return permissionGroup.map(
      (item) =>
        ({
          name: item.name,
          userIds: item.userIds ?? [],
          userGroupId: item.userGroupId ?? item.id ?? '',
          projectPermission: item.projectPermissions[PROJECT_NEW_KEY_ID],
        } as IProjectUserGroup)
    )
  }

  formatLocations(locations: IProjectLocation[]): IProjectLocationPayload[] {
    return locations.map(({ locationName, locationId, ...others }) => ({
      ...others,
      name: locationName,
      ...(!isEmpty(locationId) && { locationId }),
    }))
  }
}
