
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  ICustomFieldFormData,
  IFieldOption,
  IRepeatedVisitingTrackPointNotificationDefinition,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['dialog:close', 'update:repeatedVisitingTrackPointAlertSettings', 'update:selectedKey'],
  components: { SelectAutocomplete, InputOnlyNumber },
  name: 'RepeatTrackingPointAlertDialog',
})
export default class RepeatTrackingPointAlertDialog extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @Prop({ type: Object }) readonly barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @Prop({ type: Object, default: {} })
  readonly repeatedVisitingTrackPointAlertSettings!: IRepeatedVisitingTrackPointNotificationDefinition

  @PropSync('selectedKey', { type: String, default: '' }) _selectedKey!: string
  @Prop({ type: Array, default: {} }) readonly selectedKeys!: string[]
  @Prop({ type: String }) readonly currentBCTypeCode!: string

  repeatTPNotificationData = {} as IRepeatedVisitingTrackPointNotificationDefinition
  isShowDialog = true
  isSaveDisabled = false
  rules = {
    visitCountAlert: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    isDisplayOnMobile: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailDestinations: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailDestinationAliases: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailSubject: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailBody: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
  } as {
    visitCountAlert: Array<unknown>
    emailDestinations: Array<unknown>
    emailDestinationAliases: Array<unknown>
    emailSubject: Array<unknown>
    emailBody: Array<unknown>
    isDisplayOnMobile: Array<unknown>
  }

  get trackingPoints() {
    return this.trackingPointDatasArr.filter((val) => !val.value?.isEnd)
  }

  isTPKeyDisabled(key: string) {
    return this.selectedKeys.includes(key)
  }

  get utcCountries() {
    const utcCountries: Array<{ offset: number; country: string }> = [
      { offset: 9, country: 'Japan Standard Time, Korea Standard Time' },
      { offset: -12, country: 'Baker Island, Howland Island' },
      { offset: -11, country: 'American Samoa' },
      { offset: -10, country: 'Hawaii' },
      { offset: -9, country: 'Alaska' },
      { offset: -8, country: 'Pacific Time (USA & Canada)' },
      { offset: -7, country: 'Mountain Time (USA & Canada)' },
      { offset: -6, country: 'Central Time (USA & Canada)' },
      { offset: -5, country: 'Eastern Time (USA & Canada)' },
      { offset: -4, country: 'Atlantic Time (Canada)' },
      { offset: -3, country: 'Argentina, Brazil (West), Greenland' },
      { offset: -2, country: 'South Georgia and the South Sandwich Islands' },
      { offset: -1, country: 'Azores, Cape Verde Islands' },
      { offset: 0, country: 'UTC, London' },
      { offset: 1, country: 'Central European Time (CET), West Africa Time' },
      { offset: 2, country: 'Eastern European Time (EET), Central Africa Time' },
      { offset: 3, country: 'Moscow, East Africa Time' },
      { offset: 4, country: 'Azerbaijan Standard Time' },
      { offset: 5, country: 'Pakistan Standard Time' },
      { offset: 6, country: 'Bangladesh Standard Time' },
      { offset: 7, country: 'Indochina Time' },
      { offset: 8, country: 'China Standard Time, Singapore Standard Time' },
      { offset: 10, country: 'Australian Eastern Standard Time' },
      { offset: 11, country: 'Magadan Time' },
      { offset: 12, country: 'Fiji, New Zealand (Chatham Islands)' },
      { offset: 13, country: 'Phoenix Island Time' },
      { offset: 14, country: 'Line Islands' },
    ]

    return utcCountries
  }

  customFieldFromOptions = [] as IFieldOption[]

  get trackingPointForm() {
    return this.trackingPointDatasArr.filter((item) => !isEmpty(item.value.trackPointForms))
  }

  get emailCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.EMAIL)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get isEmailAvail() {
    return !isEmpty(this.repeatTPNotificationData.emailDestinations.filter((v) => v !== ''))
  }

  get isEmailAliasAvail() {
    return !isEmpty(this.repeatTPNotificationData.emailDestinationAliases.filter((v) => v !== ''))
  }

  addNewEmail() {
    this.repeatTPNotificationData.emailDestinations.push('')
  }

  deleteEmail(idx: number) {
    if (this.repeatTPNotificationData.emailDestinations.length === 1 && idx === 0) return
    this.repeatTPNotificationData.emailDestinations.splice(idx, 1)
  }

  addNewEmailCF() {
    this.repeatTPNotificationData.emailDestinationAliases.push('')
  }

  deleteEmailCF(idx: number) {
    if (this.repeatTPNotificationData.emailDestinationAliases.length === 1 && idx === 0) return
    this.repeatTPNotificationData.emailDestinationAliases.splice(idx, 1)
  }

  created() {
    const repeatTPNotificationData = cloneDeep(this.repeatedVisitingTrackPointAlertSettings)
    this.repeatTPNotificationData = repeatTPNotificationData
  }

  closeDialog() {
    this.$emit('dialog:close')
  }

  onSave() {
    this.isSaveDisabled = true
    this.$refs.customEventNotificationForm.validate(async (valid: string) => {
      if (valid) {
        this.loading = true
        let isEmailValid = false

        if (this.hasEmail) this.$refs.emailForm.validate((valid: string) => (isEmailValid = !!valid))
        else {
          isEmailValid = true
          if (!isEmailValid) return
          const newRecord = cloneDeep(this.repeatTPNotificationData)
          newRecord.emailDestinations = [...new Set(newRecord.emailDestinations.filter((v) => v !== ''))]
          newRecord.emailDestinationAliases = [...new Set(newRecord.emailDestinationAliases.filter((v) => v !== ''))]
          this.repeatTPNotificationData = {
            ...this.repeatTPNotificationData,
            ...newRecord,
          }
          this.$emit('update:repeatedVisitingTrackPointAlertSettings', cloneDeep(this.repeatTPNotificationData))
          this.closeDialog()
        }
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }
}
