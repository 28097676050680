<template>
  <div class="border-b border-gray-400 border-solid py-8">
    <div class="font-bold py-4">
      <h4>{{ $t('barcode_type.external_id') }}</h4>
    </div>
    <el-form :disabled="isReadOnlyMode || isDisabled">
      <div class="py-6">
        <div class="grid grid-cols-4 gap-x-4">
          <!-- trackingNumberData -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('projects.characterLimit') }}
          </div>
          <div class="col-span-3 flex flex-wrap gap-3 items-center">
            <div class="mt-2 pb-6 flex items-center">
              <div class="w-44">
                <InputOnlyNumber
                  :min="0"
                  :max="modelValue_.maxLength"
                  v-model="modelValue_.minLength"
                  name="minLength"
                  :controls="true"
                  :precision="0"
                />
              </div>
              <span class="px-2"> 〜 </span>
              <div class="w-44">
                <InputOnlyNumber
                  :min="modelValue_.minLength + 1"
                  v-model="modelValue_.maxLength"
                  name="maxLength"
                  :controls="true"
                  :precision="0"
                />
              </div>
              <span class="px-2"> {{ $t('projects.letter') }} </span>
            </div>
          </div>

          <!-- Input Support -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('projects.input_support') }}
          </div>
          <div class="col-span-3">
            <div class="mt-2 pb-6">
              <el-checkbox @change="onOCRReaderChange" name="ocrReaderType" v-model="ocrReaderType">
                {{ $t('projects.ocrReaderType') }}
              </el-checkbox>
              <el-checkbox name="barcodeReaderType" v-model="barcodeReaderType" class="ml-4">
                {{ $t('projects.barcodeReaderType') }}
              </el-checkbox>
            </div>
          </div>
          <!-- Input Support -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3"></div>
          <div class="col-span-3">
            <div>
              {{ $t('projects.save_image_as_activation_after_ocr_manual') }}
            </div>
            <div class="mt-2 pb-6">
              <el-select
                :disabled="isDisableAutoSaveOCR || !hasImageFileSetting"
                v-model="modelValue_.ocrImageAutoSaveField"
              >
                <el-option :label="$t('projects.none')" :value="''">
                  {{ $t('projects.none') }}
                </el-option>
                <el-option v-for="item in imageFileFields" :key="item.value" :label="item.label" :value="item.value">
                  {{ item.label }}
                </el-option>
              </el-select>
            </div>
          </div>

          <!-- Parser -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('barcode_type.parser') }}
          </div>
          <div class="col-span-3">
            <div class="mt-2 pb-6">
              <el-button class="btn-light-blue rounded-md" @click="openParserSettingDialog">
                {{ $t('setting') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <div class="dialog-area">
      <ParserSettingDialog v-model="isShowParserSettingDialog" v-model:parserSetting="modelValue_.parsers" />
    </div>
  </div>
</template>
<script lang="ts">
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import ParserSettingDialog from '@/components/project/dialogs/ParserSettingDialog.vue'
import { isImageFileField } from '@/utils/helpers'
import isEmpty from 'lodash/isEmpty'
import { EOCRReaderType, EReaderType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IActivationFields, IFieldOption, ITrackingNumber } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
@Options({
  components: { InputOnlyNumber, ParserSettingDialog },
  emits: ['update:modelValue'],
  name: 'ExternalIdBlock',
})
export default class ExternalIdBlock extends Vue {
  @PropSync('modelValue', { type: Object }) modelValue_!: ITrackingNumber
  @Prop({ type: Object, default: {} }) activationFields!: IActivationFields
  @Prop({ type: Boolean, default: false }) isDisabled!: boolean
  ocrReaderType = false
  barcodeReaderType = false

  isShowParserSettingDialog = false

  openParserSettingDialog() {
    this.isShowParserSettingDialog = true
  }

  get imageFileFields(): IFieldOption[] {
    return Object.entries(this.activationFields?.customFields ?? {})
      .filter((item) => isImageFileField(item[1]))
      .map(([k, val]) => ({ value: k, label: val.label }))
  }

  get hasImageFileSetting() {
    return !isEmpty(this.imageFileFields)
  }

  @Watch('activationFields', { deep: true })
  onActivationFieldsChange() {
    this.loadData()
    if (isEmpty(this.imageFileFields)) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
  }

  get isDisableAutoSaveOCR() {
    if (!this.ocrReaderType) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
    return !this.ocrReaderType
  }

  onOCRReaderChange(val: boolean) {
    if (!val) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  @Watch('ocrReaderType')
  updateOcrReader() {
    this.modelValue_.ocrReaderType = this.ocrReaderType ? EOCRReaderType.DEFAULT : EOCRReaderType.UNKNOWN
  }

  @Watch('barcodeReaderType')
  updateBarcodeType() {
    this.modelValue_.barcodeReaderType = this.barcodeReaderType ? EReaderType.DEFAULT : EReaderType.UNKNOWN
  }

  @Watch('modelValue', { immediate: true })
  loadData() {
    this.ocrReaderType = this.modelValue_?.ocrReaderType !== EOCRReaderType.UNKNOWN
    this.barcodeReaderType = this.modelValue_?.barcodeReaderType !== EReaderType.UNKNOWN
  }
}
</script>
