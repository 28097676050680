<template>
  <div class="flex flex-col items-center justify-center border p-5 border-solid border-gray-400 rounded-lg">
    <div class="flex w-full">
      <div class="flex-grow font-bold self-center">{{ $t('barcode_type.tracking_count_notification_setting') }}</div>
      <el-button
        :disabled="isDisableAddButton"
        type="primary"
        icon="el-icon-plus"
        size="medium"
        @click="addCustomEventNotification"
      >
        {{ $t('barcode_type.add_new_rule') }}
      </el-button>
    </div>
    <div v-if="Object.keys(_repeatVisitTPNotification).length !== 0" class="w-9/12">
      <div v-for="(visitTpNotification, idx) in _repeatVisitTPNotification" :key="idx" class="mb-4 px-12">
        <div class="flex items-center gap-4 py-3">
          <div class="flex items-center flex-grow">
            <div class="col-span-8 items-center whitespace-pre-line">
              <div v-html="visitTpNotification.emailSubject"></div>
            </div>
          </div>
          <div class="mr-4 flex flex-row">
            <el-button type="primary" @click="openCustomValidator(idx)" size="medium">
              {{ $t('barcode_type.settings') }}
            </el-button>
            <el-button
              type="primary"
              class="bg-red-min text-white focus:text-white"
              @click="deleteCustomValidator(idx)"
              size="medium"
            >
              {{ $t('barcode_type.delete') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-if="isShowDialog">
    <RepeatTrackingPointAlertDialog
      :currentBCTypeCode="currentBCTypeCode"
      v-model:repeatedVisitingTrackPointAlertSettings="curentSelectNotification"
      v-model:selectedKey="curentSelectedKey"
      :selectedKeys="curentSelectedKeys"
      :barcodeTypes="barcodeTypes"
      @update:repeatedVisitingTrackPointAlertSettings="saveCustomEventNotification"
      @dialog:close="closeDialog"
      :isDisabledActivateEvent="false"
    />
  </template>
</template>
<script lang="ts">
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import RepeatTrackingPointAlertDialog from '@/components/project/blocks/RepeatTrackingPointAlertDialog.vue'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  IRepeatedVisitingTrackPointNotificationDefinition,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'

export interface IRule {
  field: string
  fullField: string
  required: boolean
  type: string
  countryCode?: string
  validator: Function
}

@Options({
  components: { RepeatTrackingPointAlertDialog },
  emits: ['update:repeatedVisitingTrackPointAlertSettings'],
  name: 'RepeatTrackingPointAlertBlock',
})
export default class RepeatTrackingPointAlertBlock extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @Prop({ type: Object }) readonly barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @Prop({ type: String }) readonly currentBCTypeCode!: string
  @PropSync('repeatedVisitingTrackPointAlertSettings', { type: Object, default: {} })
  _repeatVisitTPNotification!: Record<string, IRepeatedVisitingTrackPointNotificationDefinition>

  curentSelectNotification = {} as IRepeatedVisitingTrackPointNotificationDefinition
  curentSelectedKey = '0'
  isShowDialog = false

  get curentSelectedKeys() {
    return Object.keys(this._repeatVisitTPNotification)
  }

  get isDisableAddButton() {
    return isEmpty(this.generateKey())
  }

  @Watch('trackingPointDatasArr')
  onTrackPointChange() {
    Object.keys(this._repeatVisitTPNotification).forEach((key) => {
      const isKeyExists = this.trackingPointDatasArr.some((tpDataArr) => tpDataArr.key === key)
      if (!isKeyExists) {
        delete this._repeatVisitTPNotification[key]
      }
    })
  }

  loadTP(fromIdx: string) {
    if (isEmpty(fromIdx)) return [] as ITrackPointKeyVal[]

    const keys = Object.keys(
      (this.trackingPointDatasArr.find((tpData) => tpData.key === fromIdx) as ITrackPointKeyVal)?.value
        ?.trackPointForms ?? {}
    )

    return this.trackingPointDatasArr.filter((tpData) => keys.includes(tpData.key))
  }

  createRepeatVisitTPNotification(): Record<string, IRepeatedVisitingTrackPointNotificationDefinition> | null {
    const key = this.generateKey()
    if (key) {
      return {
        [key]: {
          emailDestinations: [''],
          emailDestinationAliases: [''],
          emailSubject: '',
          emailBody: '',
          isDisplayOnMobile: false,
          visitCountAlert: 1,
        },
      }
    }
    return null
  }

  generateKey() {
    const availableTP = this.trackingPointDatasArr.filter((val) => {
      return !this._repeatVisitTPNotification[val.key]
    })
    if (availableTP.length > 0) {
      return availableTP[0].key
    }
    return null
  }

  addCustomEventNotification() {
    const newNotificationEvent = cloneDeep(this.createRepeatVisitTPNotification())
    if (newNotificationEvent) {
      this.curentSelectedKey = Object.keys(newNotificationEvent)[0]
      this.curentSelectNotification = Object.values(newNotificationEvent)[0]
      this.isShowDialog = true
    }
  }

  openCustomValidator(key: string) {
    this.curentSelectedKey = key
    const repeatVisitTPNotification = this._repeatVisitTPNotification[key]
    if (
      !repeatVisitTPNotification.emailDestinationAliases ||
      repeatVisitTPNotification.emailDestinationAliases.length < 1
    ) {
      repeatVisitTPNotification.emailDestinationAliases.push('')
    }
    if (repeatVisitTPNotification.emailDestinations.length < 1) {
      repeatVisitTPNotification.emailDestinations.push('')
    }

    this.curentSelectNotification = repeatVisitTPNotification
    this.isShowDialog = true
  }

  closeDialog() {
    this.curentSelectedKey = '0'
    this.isShowDialog = false
  }

  deleteCustomValidator(key: string) {
    delete this._repeatVisitTPNotification[key]
  }

  saveCustomEventNotification(data: IRepeatedVisitingTrackPointNotificationDefinition) {
    this._repeatVisitTPNotification[this.curentSelectedKey] = data
    this.isShowDialog = false
  }

  getTrackingPointName(id?: string) {
    const trackingPointForm = this.trackingPointDatasArr.filter((item) => item.key === id)
    return trackingPointForm?.[0]?.value.name || ''
  }
}
</script>
